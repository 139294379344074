import React, { useState } from 'react';

// 컬러 설정
const mainColor = 'rgb(63, 100, 243)'; // 텍스트와 테두리의 기본 색상
const whiteColor = '#FFFFFF'; // 흰색

// Button 스타일 참조
const fileUploadButtonStyle = (isHovered) => ({
    
    backgroundColor: isHovered ? mainColor : whiteColor,
    color: isHovered ? whiteColor : mainColor,
    border: `0.5px solid grey`, // 항상 같은 테두리 색상
    borderRadius: '12px', // 모서리를 둥글게
    padding: '0.2px 0.2px', // 버튼 패딩 설정
    // height: '30px',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    boxShadow: isHovered 
        ? '4px 4px 12px rgba(63, 100, 243, 0.3)'  // 마우스 오버 시 더 강한 그림자
        : '2px 2px 8px rgba(0, 0, 0, 0.1)',       // 기본 그림자
    fontSize: '15px',
    fontWeight: 'bold',
    lineHeight: '30px',
    textAlign: 'center',
    justifyContent: 'center', /* 가로 중앙 정렬 */
    alignItems: 'center', /* 세로 중앙 정렬 */
    width: '200px', // 버튼 너비 명시적 설정
    height: '36px', // 버튼 높이 명시적 설정
});

const FileSelectBtn = ({btnName}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <label 
            htmlFor="ex_file" 
            className="file-upload-button" 
            style={fileUploadButtonStyle(isHovered)} 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {btnName}
        </label>
    );
};

export default FileSelectBtn;